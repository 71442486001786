export const SET_TOKEN=(token)=>{
    sessionStorage.setItem("Token",token)
}
export const GET_TOKEN=()=>{
    return sessionStorage.getItem("Token")
}
export const REMOVE_TOKEN=()=>{
    return sessionStorage.removeItem("Token")
}
export const SET_platForm=(platForm)=>{
    sessionStorage.setItem("platForm",JSON.stringify(platForm))
}
export const GET_PLATFORM=()=>{
    return sessionStorage.getItem("platForm")
}
export const REMOVE_PLATFORM=()=>{
    return sessionStorage.removeItem("platForm")
}
export const SET_MENU=(Menu)=>{
    sessionStorage.setItem("Menu",JSON.stringify(Menu))
}
export const GET_MENU=()=>{
    return sessionStorage.getItem("Menu")
}
export const REMOVE_MENU=()=>{
    return sessionStorage.removeItem("Menu")
}
export const SET_PLAYLOAD=(playLoad)=>{
    sessionStorage.setItem("playLoad",JSON.stringify(playLoad))
}
export const GET_PLAYLOAD=()=>{
    return JSON.parse(sessionStorage.getItem("playLoad"))
}
export const REMOVE_PLAYLOAD=()=>{
    return sessionStorage.removeItem("playLoad")
}
export const SET_PROJECTID=(projectID)=>{
    sessionStorage.setItem("projectId",JSON.stringify(projectID))
}
export const GET_PROJECTID=()=>{
    return JSON.parse(sessionStorage.getItem("projectId"))
}
export const REMOVE_PROJECTID=()=>{
    return sessionStorage.removeItem("projectId")
}
export const SET_HHTOKEN=(token)=>{
    sessionStorage.setItem("hhtoken",JSON.stringify(token))
}
export const GET_HHTOKEN=()=>{
    return JSON.parse(sessionStorage.getItem("hhtoken"))
}
export const REMOVE_HHTOKEN=()=>{
    return sessionStorage.removeItem("hhtoken")
}
export const SET_USERID=(userID)=>{
    sessionStorage.setItem("userId",JSON.stringify(userID))
}
export const GET_USERID=()=>{
    return JSON.parse(sessionStorage.getItem("userId"))
}
export const REMOVE_USERID=()=>{
    return sessionStorage.removeItem("userId")
}