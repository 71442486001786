import store from '@/store/index';
import router from '@/router/index';
import { requestfly } from './utils/request';
import { GET_HHTOKEN, GET_PLAYLOAD, GET_PROJECTID, GET_TOKEN, GET_USERID, REMOVE_HHTOKEN, REMOVE_MENU, REMOVE_PLAYLOAD, REMOVE_PROJECTID, REMOVE_TOKEN, REMOVE_USERID, SET_HHTOKEN, SET_PLAYLOAD, SET_PROJECTID, SET_TOKEN, SET_USERID,SET_MENU, GET_MENU } from './utils/token';
import { Message } from 'element-ui';
router.beforeEach(async (to, from, next) => {
    if (to.path == '/404' || to.path == '/login'||to.path=="/share/shareVideo") {
        next();
    } else if (!(to.query.projectID || to.query.token || to.query.userID)) {
        if (GET_TOKEN() && !GET_HHTOKEN()) {
            next()
        } else {
            remove();
            next('/login');
        }
    }
    else{
        if (GET_PROJECTID() === to.query.projectID && GET_HHTOKEN() === to.query.token && GET_USERID() === to.query.userID) {
            next()
        } else {
            getToken(to,next);
        }
    }
});
function parseJwt(token) {
    // 分割JWT的三个部分
    let base64Url = token.split('.')[1];
    // 将Base64URL编码的载荷部分解码
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function getToken(to,next){
    requestfly.post('/HHCommunicate/GetHaiHongJWT?projectId=' + to.query.projectID + '&token=' + to.query.token + '&userId=' + to.query.userID).then(res => {
        if (res.statusCode == 200) {
            let payload = parseJwt(res.data)
            SET_PLAYLOAD(payload)
            SET_TOKEN(res.data)
            SET_PROJECTID(to.query.projectID)
            SET_HHTOKEN(to.query.token)
            SET_USERID(to.query.userID)
            getMenu(payload,next);
          
        } else {
            Message({
                type: 'error',
                message: res.message
            });
            remove();
            next('/404')
        }
    }).catch(err => {
        Message({
            type: 'error',
            message: err.message
        });
        remove();
        next('/404')
    })
}
function remove(){
    REMOVE_HHTOKEN();
    REMOVE_MENU();
    REMOVE_PLAYLOAD();
    REMOVE_TOKEN();
    REMOVE_PROJECTID();
    REMOVE_USERID();
}
function getMenu(playload,next){
    requestfly.post("/User/GetMenu").then(async (res) => {
        if (res.statusCode == 200) {
            let menus=res.data;
            menus=filterMenu(menus, JSON.parse(playload?.MenuCode))
            SET_MENU(menus);
            store.commit('setMenu', menus);
            window.location.reload();
            next();
        } else {
            Message({
                type: 'error',
                message: res.message
            });
        }
    }).catch(err => {
        Message({
            type: 'error',
            message: err.message
        });
    })
}
function filterMenu(menu, code) {
    return menu.filter(item => {
      // 检查当前菜单项的编码是否在给定的编码数组中
      if (code.includes(item.permissionCode)) {
        // 如果有子菜单，递归过滤子菜单
        if (item.children && item.children.length > 0) {
          item.children = filterMenu(item.children, code);
        }
        return true;
      }
      return false;
    });
  }